import React, { useState } from 'react'
import { filterationdata } from './Datas'
const REFilter = (props) => {
  let {show}=props
   let pricefilter=filterationdata.price
   let locationfilter =filterationdata.location
   let [pincode,setpincode]=useState("")
   let [doorfacing,setdoorfacing]=useState("")
   let [district,setdistrict]=useState("")
   let [state,setstate]=useState("")
   const [pricefiltered, setPriceFiltered] = useState([]);
   const [filteredlocation, setFilteredLocation] = useState([]);
   
   console.log(pricefiltered);
  return (
    <div style={{display : show=="show"? "block":"none"}}
    className='w-[350px] my-4 d-md-block h-fit transi'>
       <div className='container  p-2'>
          <p className='d-none d-sm-block fw-bold text-xl mb-1'>Filter</p>
         <div className='flex flex-wrap align-items-center gap-2'>
         <p className='p-2 bg-white m-0 fw-semibold'>Price : </p>
         {
          pricefilter.map((price,index)=>{
            return(
    <button onClick={()=>{
      setPriceFiltered((prevPrices) => {
        if (prevPrices.includes(price)) {
          return prevPrices.filter((p) => p !== price);
        } else {
          return [...prevPrices, price];
        }
      });
            }} 
       style={{backgroundColor: pricefiltered.includes(price) ? "rgb(191,202,2)":"whitesmoke",
      color: pricefiltered.includes(price)? "white": "black"}} 
       className='border-1 rounded p-2'>
                                    {price}
  </button>
            )
          })
         }
         </div>
         {/* Place */}
         <div className='flex gap-2 mt-2 flex-wrap align-items-center '>
          <p className='p-2 fw-semibold m-0'>Place : </p>
          {
          locationfilter.map((place)=>{
            return(
<button onClick={()=>{
   setFilteredLocation((preloc) => {
    if (preloc.includes(place)) {
      return preloc.filter((p) => p !== place);
    } else {
      return [...preloc, place];
    }
  });
        }} 
   style={{backgroundColor: filteredlocation.includes(place) ? "rgb(191,202,2)":"whitesmoke",
  color: filteredlocation.includes(place)? "white": "black"}} 
  className='bg-slate-50 focus-within:bg-blue-600 text-slate-900 focus-within:text-slate-50 border-1 rounded p-2'>
  {place}
  </button>
            )
          })
         }
         </div>
         {/* Place filter finished */}
         <div className='flex justify-between my-2'>
          <p className='fw-semibold m-0 py-2'>State :</p>
          <select id='state' onChange={(e)=>setstate(e.target.value)} className='border-1 w-3/5 bg-white p-2' value={state}>
            <option value="">State</option>
            
          </select>
         </div>
         {/* State finish */}
         <div className='flex justify-between my-2'>
          <p className='fw-semibold m-0 py-2'>District :</p>
          <select id='district' onChange={(e)=>setdistrict(e.target.value)}  className='border-1 w-3/5 bg-white p-2' value={district} >
            <option value="">District</option> 
            
          </select>
         </div>
         {/* District */}
         <div className='flex justify-between my-2'>
          <p className='fw-semibold m-0 py-2'>Door Facing :</p>
          <select id='door'onChange={(e)=>setdoorfacing(e.target.value)} className='border-1 w-3/5 bg-white p-2' value={doorfacing}>
            <option value="">Facing</option> 
            <option value="east">East</option> 
            <option value="west">West</option> 
            <option value="north">North</option> 
            <option value="south">South</option> 

            
          </select>
         </div>
         {/* Door Facing */}

         <div className='flex justify-between my-2'>
          <p className='fw-semibold m-0 py-2'>Pincode :</p>
          <input type="number" value={pincode} onChange={(e)=>{
            const enterpincode=e.target.value;
           if(enterpincode.length<=6){
            setpincode(enterpincode)
           }
          }} className='border-1 w-3/5 bg-white p-2' />
         </div>
         {/* Pincode finish */}

         <button onClick={()=>{
         }} className='p-2 d-none d-sm-block bgclr my-3 rounded'>Show Result</button>
       </div>
    </div>
  )
}

export default REFilter