import React, { useState } from 'react'

const RenovationService = () => {
    let data=[
        {
            "id":1,
            "title":"Agreement",
            "para":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi ea aut tempora tenetur ut vitae earum dolorem nemo, ratione, eveniet aliquam natus error in. Commodi obcaecati deleniti dolorum earum asperiores.",
            "img":"../Asset/agreementclr.png",
            "img2":"../Asset/agreement.png"
        },
        {
            "id":2,
            "title":"Agreement",
            "para":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi ea aut tempora tenetur ut vitae earum dolorem nemo, ratione, eveniet aliquam natus error in. Commodi obcaecati deleniti dolorum earum asperiores.",
            "img":"../Asset/interior-designclr.png",
            "img2":"../Asset/interior-design.png"
        },
        {
            "id":3,
            "title":"Agreement",
            "para":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi ea aut tempora tenetur ut vitae earum dolorem nemo, ratione, eveniet aliquam natus error in. Commodi obcaecati deleniti dolorum earum asperiores.",
            "img":"../Asset/staircaseclr.png",
            "img2":"../Asset/staircase.png"
        },
        {
            "id":4,
            "title":"Agreement",
            "para":"Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi ea aut tempora tenetur ut vitae earum dolorem nemo, ratione, eveniet aliquam natus error in. Commodi obcaecati deleniti dolorum earum asperiores.",
            "img":"../Asset/houseclr.png",
            "img2":"../Asset/house.png"
        }
    ]
    let [id,setid]=useState(null)

  return (
    <div className='xl:min-h-[88vh] bgpattern'>
        <div className='container pt-10'>
        <div className='relative w-fit right-4 mx-auto'>
                <div className='w-[40px] h-[40px] border-4 border-lime-600'>
                </div>
                <p className='absolute top-2 fw-semibold worksan left-6'>SERVICES</p>
            </div>
                <p className='text-3xl xl:ms-5 text-center my-4 fw-semibold poppins'>Our Speciallization</p>
            <div className='flex flex-wrap justify-center sm:justify-between mt-10'>

                {
                    data.map((info,index)=>{

                        return(
               <div 
               onClick={()=>{
                if(id==info.id)
                setid("")
                else
                setid(info.id)
               }}
               onMouseEnter={()=>{
                setid(info.id)
               }}
               onMouseLeave={()=>{
                setid(null)
               }}
               style={{backgroundColor: id==info.id ? "#2182b0" : "white"}} 
               className ={`w-[18rem] transi m-3 m-sm-1 rounded shadow p-3`}>
                    <img style={{display : id==info.id ? "none" : "block"}} className='w-[70px] my-3 h-[70px]' src={info.img} alt="" />
                    <img style={{display : id==info.id ? "block" : "none"}} className='w-[70px] my-3 h-[70px]' src={info.img2} alt="" />
                    <p style={{color : id==info.id ? "white" : "black"}} className='text-xl  mb-1 mt-2 poppins'>{info.title} </p>
                    <p style={{color : id==info.id ? "white" : ""}} className='text-slate-400 pe-1'>
                        {info.para} </p>
                    <button style={{color : id==info.id ? "white" : ""}} className='text-uppercase fw-semibold poppins text-sm'>View Service</button>
                </div>
                        )
                    })
                }
             </div>
        </div>

    </div>
  )
}

export default RenovationService