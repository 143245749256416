import React from 'react'

const Projectdatahome=[
    
    {
        "title":"Cathedral of barisa",
        "category":"Architecture",
        "img":"../Asset/project-2.jpg"
    },
    {
        "title":"Cathedral of barisa",
        "category":"Architecture",
        "img":"../Asset/project-3.jpg"
    },
    {
        "title":"Cathedral of barisa",
        "category":"Architecture",
        "img":"../Asset/project-4.jpg"
    },
    {
        "title":"Cathedral of barisa",
        "category":"Architecture",
        "img":"../Asset/project-5.jpg"
    },
    {
        "title":"Cathedral of barisa",
        "category":"Architecture",
        "img":"../Asset/project-6.jpg"
    }

]
const testimonialdata=[
    {
        "name":"Ferdrick",
        "img":"../Asset/testi-1.jpg",
        "para":`Certainly! If you provide me with some information or context, I'd be happy to help you craft a small testimonial. For instance, let me know what product, 
        service, or experience you'd like the testimonial to be about`,
        "position":"Director"
    },
    {
        "name":"Ferdrick",
        "img":"../Asset/testi-2.jpg",
        "para":`Certainly! If you provide me with some information or context, I'd be happy to help you craft a small testimonial. For instance, let me know what product, 
        service, or experience you'd like the testimonial to be about`,
        "position":"Director"
    },
    {
        "name":"Ferdrick",
        "img":"../Asset/testi-3.jpg",
        "para":`Certainly! If you provide me with some information or context, I'd be happy to help you craft a small testimonial. For instance, let me know what product, 
        service, or experience you'd like the testimonial to be about`,
        "position":"Director"
    },
    {
        "name":"Ferdrick",
        "img":"../Asset/testi-2.jpg",
        "para":`Certainly! If you provide me with some information or context, I'd be happy to help you craft a small testimonial. For instance, let me know what product, 
        service, or experience you'd like the testimonial to be about`,
        "position":"Director"
    }
]
let InteriorHouse=[
    {
        "img":"../Asset/hotel.png",
        "title":"Bedroom",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/bath.png",
        "title":"BathRoom",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/kitchen.png",
        "title":"Kitchen",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/interior-design.png",
        "title":"Living Room",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/game-controller.png",
        "title":"Gaming Room",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/staircase.png",
        "title":"Home Office setup",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    }
]
let InteriorCommercial=[
    {
        "img":"../Asset/workplace.png",
        "title":"Office",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/game-controller.png",
        "title":"Enterainment area",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/interior-design.png",
        "title":"Individual Office",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },  
   
    {
        "img":"../Asset/staircase.png",
        "title":"Entire Office setup",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/food.png",
        "title":"Restarunt",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    },
    {
        "img":"../Asset/kitchen.png",
        "title":"Office Patry",
        "para":"We offer a fully integrated service that complements the architectural concept, we ensure all projects."
    }
]
let filterationdata={
    "price":[
        "1Lakh-20Lakh",
        "20L-50L",
        "50L-1C",
        "1C-2C"
    ],
    "location":[
        "Koramangala",
        "BTM Layout",
        "Whitefield",
        "JP Nagar"
    ]
}
export {Projectdatahome,testimonialdata,InteriorCommercial,InteriorHouse,filterationdata}