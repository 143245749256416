import { Carousel, Offcanvas } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { CarouselItem } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { useNavigate } from 'react-router'
import REFilter from './REFilter'

const RealEstateProperties = (props) => {
    const data=[
      {
        "Pid":"Kt234",
        "title":"Cathedral of barisa",
        "line1":"encowefqo",
        "line2":"cvwuev iebfqihef cwibe",
        "state":"TamilNadu",
        "district":"tirunelveli",
        "pincode":"627006",
        "price":"4000000",
        "category":"Formland",
        "subCategory":"",
        "Facing":"West",
        "description":"piping , electricity,fencing",
        "img":[
          "../Asset/project-1.jpg",
          "../Asset/project-2.jpg",
          "../Asset/project-3.jpg",
          "../Asset/project-4.jpg",
          "../Asset/project-5.jpg",
          "../Asset/project-6.jpg",
        ]
    },
    {
      "Pid":"Kt234",
      "title":"Cathedral of barisa",
      "line1":"encowefqo",
      "line2":"cvwuev iebfqihef cwibe",
      "state":"TamilNadu",
      "district":"tirunelveli",
      "pincode":"627006",
      "price":"4000000",
      "category":"Formland",
      "subCategory":"",
      "Facing":"",
      "description":"piping , electricity,fencing",
      "img":[
        "../Asset/project-1.jpg",
        "../Asset/project-2.jpg",
        "../Asset/project-3.jpg",
        "../Asset/project-4.jpg",
        "../Asset/project-5.jpg",
        "../Asset/project-6.jpg",
      ]
  },
  {
    "Pid":"Kt234",
    "title":"Cathedral of barisa",
    "line1":"encowefqo",
    "line2":"cvwuev iebfqihef cwibe",
    "state":"TamilNadu",
    "district":"tirunelveli",
    "pincode":"627006",
    "price":"4000000",
    "category":"Formland",
    "subCategory":"",
    "Facing":"",
    "description":"piping , electricity,fencing",
    "img":[
      "../Asset/project-1.jpg",
      "../Asset/project-2.jpg",
      "../Asset/project-3.jpg",
      "../Asset/project-4.jpg",
      "../Asset/project-5.jpg",
      "../Asset/project-6.jpg",
    ]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"tirunelveli",
  "pincode":"627006",
  "price":"4000000",
  "category":"Formland",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"tirunelveli",
"pincode":"627006",
"price":"4000000",
"category":"Formland",
"subCategory":"",
"Facing":"",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
},
{
  "Pid":"Kt234",
  "title":"Cathedral of barisa",
  "line1":"encowefqo",
  "line2":"cvwuev iebfqihef cwibe",
  "state":"TamilNadu",
  "district":"madurai",
  "pincode":"627006",
  "price":"4000000",
  "category":"Resort",
  "subCategory":"",
  "Facing":"",
  "description":"piping , electricity,fencing",
  "img":[
    "../Asset/project-1.jpg",
    "../Asset/project-2.jpg",
    "../Asset/project-3.jpg",
    "../Asset/project-4.jpg",
    "../Asset/project-5.jpg",
    "../Asset/project-6.jpg",
  ]
},
{
"Pid":"Kt234",
"title":"Cathedral of barisa",
"line1":"encowefqo",
"line2":"cvwuev iebfqihef cwibe",
"state":"TamilNadu",
"district":"Chennai",
"pincode":"627006",
"price":"4000000",
"category":"Buildings",
"subCategory":"Villas",
"Facing":"East",
"description":"piping , electricity,fencing",
"img":[
  "../Asset/project-1.jpg",
  "../Asset/project-2.jpg",
  "../Asset/project-3.jpg",
  "../Asset/project-4.jpg",
  "../Asset/project-5.jpg",
  "../Asset/project-6.jpg",
]
}
  
    ]
    // console.log(data);
    let [active,setactive]=useState("")
    let [anime,setanime]=useState("")
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage=6;
    const [currentItems,setcurrentItems]=useState(data.slice(itemOffset,itemOffset+itemsPerPage));
    const pageCount=Math.ceil(data.length/itemsPerPage)


  
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % data.length;
      setItemOffset(newOffset);
      setcurrentItems(data.slice(newOffset,newOffset+itemsPerPage))
    };
    useEffect(()=>{
      setactive("viewall")
      setanime("animate__fadeIn");
      const element = document.querySelector('.durationani');
      element.style.setProperty('--animate-duration', '4s');
      setTimeout(() => {
        setanime("")
      }, 2000);
    // console.log(anime);

    },[currentItems])
    let navigate=useNavigate()
    let [show,setshow]=useState(false)
  return (
    <div className='transi container mx-auto'>
      <button className='flex my-2 float-right gap-2 align-items-center text-lg d-sm-none'>
        {/* Filter */}
        <img onClick={()=>setshow(true)} className='w-[25px] h-[35px]  ' src={require("../Asset/filter.png")} alt="" />
      </button>
      <Offcanvas show={show} className="transi" onHide={()=>setshow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <REFilter show="show"/>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Computer screen */}
    <div style={{display : active=="building"||active=="Villas"||active=="Apartment"||active=="House"||active=="Commercial"? "none" :"flex" }}
    className='flex container my-sm-4 justify-around mx-auto flex-wrap'>
      <p onClick={  ()=>{setactive("viewall");
      sessionStorage.setItem("realestate","viewall")}} 
      style={{color: active=="viewall"? "green": ""}}
      className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>View All</p>
      <p onClick={()=>{setactive("formland");sessionStorage.setItem("realestate","formland")}} 
      style={{color: active=="formland"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>Form Land</p>
      <p onClick={()=>{setactive("plot");sessionStorage.setItem("realestate","plot")}} 
      style={{color: active=="plot"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>Plot</p>
      <p onClick={()=>{setactive("resort");sessionStorage.setItem("realestate","resort")}} 
      style={{color: active=="resort"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>Resort</p>
      <p onClick={()=>{setactive("building");sessionStorage.setItem("realestate","building")}}
      style={{color: active=="building"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500  fw-semibold poppins'>Buildings</p>
    </div>

  <div style={{display : active=="building"||active=="Villas"||active=="Apartment"||active=="House"||active=="Commercial"? "flex" :"none" }}
    className='flex container my-4 justify-around mx-auto flex-wrap'>
      <p onClick={  ()=>{setactive("viewall");
      sessionStorage.setItem("realestate","viewall")}} 
      style={{color: active=="viewall"? "green": ""}}
      className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>View All</p>
      <p onClick={()=>{setactive("Apartment");sessionStorage.setItem("realestate","Apartment")}} 
      style={{color: active=="Apartment"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>Apartment</p>
      <p onClick={()=>{setactive("House");sessionStorage.setItem("realestate","House")}} 
      style={{color: active=="House"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>House</p>
      <p onClick={()=>{setactive("Commercial");sessionStorage.setItem("realestate","Commercial")}} 
      style={{color: active=="Commercial"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500 fw-semibold poppins'>Commercial</p>
      <p onClick={()=>{setactive("Villas");sessionStorage.setItem("realestate","Villas")}}
      style={{color: active=="Villas"? "green": ""}} className='w-fit cursor-pointer p-2 hover:text-lime-500  fw-semibold poppins'>Villas</p>
    </div>




<div  className={`${anime} animate__animated transi durationani flex  justify-around flex-wrap`}>
    {
      currentItems.map((data,index)=>{
        return(
          <div onClick={()=>{
            navigate(`/realestate/${data.title}`)
          }} className='w-[22rem] rounded shadow m-2'>
               <Carousel>
                {
                  data.img.map((pic)=>{
                    return(
                      <Carousel.Item interval={1000}>
                        <img className='rounded-lg' src={pic} alt="" />

                      </Carousel.Item>
                    )
                  })
                }
               
               </Carousel>
               {/* COntent */}
               <div className='mt-2 p-2 scroll-bar flex gap-2 flex-wrap overflow-y-scroll h-[300px]'>
                <p className='bg-slate-100 rounded p-2 w-fit'> <span className='textclr'> Property Name </span>  : Vidhaya villas </p>

             
              <p className='bg-slate-100 rounded p-2 w-fit'>
               <span className='textclr'> Property </span> : {data.category} </p>
               <p className='bg-slate-100 rounded p-2 w-fit'>  <span className='textclr'> Price </span>  : 1000/sqt.ft</p>
              {
                data.Facing.length>2 ? 
                <p className='bg-slate-100 rounded  p-2 w-fit'>  
                <span className='textclr'> Door facing </span>  
                : {data.Facing}</p>
              :
              ""
              }
              <p className='bg-slate-100 rounded p-2 w-fit'>
              <span className='textclr'> Address </span>  : {data.line1}, {data.line2},{data.district},{data.state},{data.pincode}.
              </p>
              <button className='p-2 bg-red-600 text-slate-50 rounded'>Get Owner Details</button>
              
               </div>

          </div>
        )
      })
    } 
    </div>
         <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          containerClassName='pagination'
          pageLinkClassName='page-num'
          previousLinkClassName='page-num'
          nextLinkClassName='page-num'
          activeLinkClassName='active'
        />
        





        
    </div>
  )
}

export default RealEstateProperties